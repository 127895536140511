html {
  height: 100%;
  font-size: 16px;

  body {
    display: grid;
    height: 100%;
    margin: 0;
    grid-template-areas: 'header' 'action-bar' 'search-bar' 'content' 'footer';
    grid-template-rows: max-content max-content max-content 1fr max-content;
    grid-template-columns: 1fr;
    font-family: 'Roboto';
    font-size: 0.875rem;
    font-style: normal;
    line-height: 1.25em;

    #app,
    #root {
      display: contents;
    }

    header {
      grid-area: header;
    }

    .action-bar {
      grid-area: action-bar;
    }

    .search-bar {
      grid-area: search-bar;
    }

    .page {
      grid-area: content;
    }

    footer {
      grid-area: footer;
    }
  }
}

a {
  text-decoration: none;
}

.MuiButton-startIcon {
  // align to text vertical center properly
  transform: translateY(-1px);
}

.MuiSvgIcon-root.flipped {
  transform: scaleY(-1);
}
