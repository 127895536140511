.MuiButton-root.MuiButton-contained {
  min-width: 7rem;
  height: 2.25rem;
  padding: 0 0.5rem;
  border: 1px solid $primary;
  text-transform: none;

  &.primary-button {
    color: $dialog-background;
    background-color: $primary;
    &:hover {
      background-color: $primary;
    }
  }

  &.secondary-button {
    color: $primary;
    background-color: $dialog-background;
    border: 1px solid $primary;
    &:hover {
      background-color: $dialog-background;
    }
  }

  &.small-button {
    min-width: 7rem;
    height: auto;
    padding: 0.5rem;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.1;
  }
}
