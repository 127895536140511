header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.25rem 1rem;

  .title-wrapper {
    display: flex;
    align-items: center;
    gap: 1rem;
    color: $primary;

    .app-logo {
      height: 1.5rem;
    }

    .app-name {
      font-family: 'Varela Round';
      font-size: 1.5rem;
    }
  }

  .right-side-block {
    height: 2.5rem;
    display: flex;
    align-items: center;
    gap: 1.5rem;

    .menu-title {
      display: flex;
      align-items: center;
      color: $primary;
      cursor: pointer;

      .MuiIconButton-root {
        padding: 0;
        color: $primary;

        &.expand-button {
          .MuiSvgIcon-root {
            width: 1.25rem;
          }
        }
      }
    }

    .administration-block {
      display: flex;
      align-items: center;
      gap: 2rem;
    }

    .user-block {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.75rem;

      .username {
        color: $primary;
        text-align: right;
      }

      .profile-button {
        background-color: $primary;
        color: $button-color;
      }
    }
  }
}

.header-menu-list {
  a,
  li {
    font-size: 0.875rem;
    color: $primary;
    width: 100%;
  }
}
