.transaction-list-page {
  .transaction-list {
    display: grid;
    grid-template-columns: 4fr 3fr 3fr 1fr 3fr;
    grid-row-gap: 0.25rem;

    .table-head-total {
      justify-self: flex-end;
    }

    .table-head-status {
      .table-head {
        cursor: auto;
      }
    }

    .transaction-item {
      .field-data {
        max-width: 60rem;
      }
    }

    .amount {
      display: flex;
      justify-content: flex-end;
    }
  }

  .transaction-list-message {
    text-align: center;
  }
}

.transactions-date-picker {
  .MuiOutlinedInput-input {
    padding: 0.25rem 0.5rem;
    width: 6rem;
  }
  .MuiSvgIcon-root {
    width: 1.25rem;
    height: 1.25rem;
    color: #174b85;
  }
  .MuiInputBase-root {
    padding-right: 0.5rem;
  }
}

.MuiPaper-root {
  .MuiCheckbox-root {
    padding: 0 0.5rem 0 0;
  }
}

.transactions-select {
  .MuiSelect-select {
    width: 7rem;
  }
}
