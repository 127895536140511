.action-bar {
  display: grid;
  height: 3rem;
  padding: 0rem 0.5rem;
  align-items: center;
  background-color: $primary;
  color: $button-color;
  font-family: 'Rubik', sans-serif;
  font-size: 1.25rem;
  grid-column-gap: 1rem;
  grid-template-areas: 'back breadcrumbs title actions';
  grid-template-columns: 1fr 8fr 20fr 9fr;
  text-transform: uppercase;

  .back {
    grid-area: back;
    .MuiSvgIcon-root {
      cursor: pointer;
    }
  }

  .breadcrumbs {
    text-transform: none;
    .MuiTypography-root {
      color: $breadcrumbs-text;
      font-size: 0.875rem;
    }
  }

  .title {
    letter-spacing: 0.0625rem;
    grid-area: title;
    text-align: center;
  }

  .actions {
    display: flex;
    align-items: center;
    justify-self: flex-end;
    grid-area: actions;
    gap: 0.5rem;

    .action-button {
      width: 2rem;
      height: 2rem;
      border-radius: 0.125rem;
      background: $secondary;
      color: $button-color;
    }
  }
}
