.table {
  .table-body {
    margin-bottom: 2rem;

    .table-heads {
      display: contents;

      .table-head {
        width: fit-content;
        display: flex;
        align-items: center;
        padding: 0.25rem 1rem;
        font-size: 0.875rem;
        font-weight: 500;
        text-transform: uppercase;
        cursor: pointer;

        .MuiSvgIcon-root {
          margin: 0.25rem;
          color: $primary;
          font-size: 1.25rem;
        }

        .sort-placeholder {
          width: 1.25rem;
          height: 1.25rem;
          margin: 0.25rem;
        }
      }

      .actions-column-placeholder {
        display: contents;
      }
    }

    .table-content {
      display: contents;

      > * {
        padding: 0.5rem 1rem;
        background-color: $row-background;
      }

      .field-data {
        display: flex;
        align-items: center;

        > span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .actions {
        display: flex;
        gap: 0.25rem;
        padding-right: 0.5rem;
        .MuiIconButton-root {
          padding: 0;
          .MuiSvgIcon-root {
            width: 1.25rem;
            height: 1.25rem;
            color: $primary;
          }
        }
      }
    }
  }
}
