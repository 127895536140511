.error-page {
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;

  .MuiSvgIcon-root {
    font-size: 4rem;
  }

  .message {
    font-size: 2rem;
  }
}
