.closure-page {
  .closure-list-container,
  .closure-form,
  .closure-details,
  .grouped-closure-list,
  .closure-transaction-list-container,
  .system-closure-transaction-list-container {
    width: 70%;
    margin: 0 auto;
  }

  .closure-form,
  .closure-details {
    .section-content {
      display: flex;
      flex-direction: column;
      align-items: normal;

      .closure-form-item {
        display: grid;
        grid-template-columns: 20% 60%;
        grid-column-gap: 1rem;

        .label {
          font-weight: 500;
        }

        .value {
          display: flex;
          align-items: flex-start;
          .MuiTextField-root {
            flex: 1;
          }
        }

        &.closure-form-date {
          align-items: center;
        }

        .closure-note {
          text-align: justify;
        }

        .system-closure-close {
          display: flex;
          gap: 1rem;
          align-items: center;
        }
      }

      .form-actions-container {
        display: flex;
        justify-content: center;
        gap: 1rem;
      }

      .MuiIconButton-root {
        padding: 0 0.25rem;
        .MuiSvgIcon-root {
          width: 1.25rem;
          height: 1.25rem;
          color: $primary;
        }
      }

      .MuiOutlinedInput-root {
        padding: 0.25rem 0.75rem;
        font-size: 0.875rem;
        background-color: $input-background;
        .MuiOutlinedInput-input {
          padding: 0;
        }
      }
    }
  }

  .closure-list-container,
  .grouped-closure-list,
  .closure-transaction-list-container,
  .system-closure-transaction-list-container {
    .closure-list-title {
      @extend %section-title;
      margin-top: 2rem;
    }

    .closure-list,
    .grouped-closure-list-table,
    .closure-transaction-list,
    .system-closure-transaction-list {
      display: grid;
      grid-row-gap: 0.25rem;

      .table-head-total,
      .table-head-totalCommission,
      .table-head-commissionReducedTotal {
        justify-self: flex-end;
      }

      .closure-item {
        .closure-open,
        .closure-closed {
          display: flex;
          align-items: center;
          gap: 0.25rem;

          svg {
            width: 1.25rem;
            height: 1.25rem;
          }
        }

        .closure-open {
          svg {
            color: $warning;
          }
        }

        .closure-closed {
          svg {
            color: $secondary;
          }
        }
      }
    }

    .closure-list,
    .grouped-closure-list-table {
      .table-heads .actions-column-placeholder {
        display: grid;
      }
    }

    .closure-list {
      grid-template-columns: repeat(3, 4fr) 1fr;
    }

    .grouped-closure-list-table {
      grid-template-columns: 4fr 4fr 3fr 4fr 4fr 3fr 1fr;
    }

    .closure-transaction-list {
      grid-template-columns: repeat(3, 1fr);
    }

    .system-closure-transaction-list {
      grid-template-columns: 3fr 2fr 2fr 2fr 1fr 2fr;
    }

    .amount {
      display: flex;
      justify-content: flex-end;
    }

    .closure-list-message {
      margin: 1rem 0;
      text-align: center;
    }

    .loading {
      position: relative;
      width: auto;
      height: auto;
      background-color: $loading-background;
      margin: 2rem;
    }
  }

  .loading {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.closure-start-confirmation-modal {
  .MuiDialogContent-root {
    align-items: center;
  }
}

.action-button {
  &.csv-export {
    padding: 5px;
    path {
      stroke: $button-color;
    }
  }

  &.pdf-export {
    padding: 5px;
    rect,
    polygon,
    path {
      fill: $button-color;
    }
  }
}
