.form-section {
  align-items: center;

  .section-title {
    @extend %section-title;
  }

  .section-content {
    display: grid;
    align-items: center;
    padding: 1rem;
    background-color: $section-background;
    grid-template-columns: repeat(2, max-content 1fr);
    column-gap: 1rem;
    row-gap: 1rem;
  }

  .field {
    display: contents;

    .label {
      line-height: 2.125rem;
    }

    .widget {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;

      .error {
        color: $error;
      }
    }
  }
}
