.dialog {
  align-items: center;
  justify-content: center;

  .MuiDialog-paper {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    gap: 2rem;

    .MuiDialogTitle-root {
      padding: 0;
      color: $primary;
      font-family: 'Roboto', sans-serif;
      font-size: 1.5rem;
      font-weight: 500;
      text-align: center;
    }

    .MuiDialogContent-root {
      display: flex;
      padding: 0;
      flex-direction: column;
      gap: 1.5rem;
    }

    .save-error {
      display: flex;
      gap: 0.25rem;
      justify-content: center;
      margin: -1rem 0;
      color: $error;
    }

    .MuiDialogActions-root {
      display: flex;
      padding: 0;
      align-items: center;
      justify-content: center;
      gap: 1rem;

      .MuiButton-root {
        font-weight: 400;
      }
    }
  }
}

.system-dialog {
  .field-apiKey,
  .field-invoice-description {
    grid-column: span 3;
  }

  .show-password-icon {
    width: 1.25rem;
    height: 1.25rem;
    color: $primary;
    margin: 0;
  }
}
