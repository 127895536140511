$primary: #174b85;
$primary-accent: #dae3ef;
$secondary: #97b513;
$dialog-background: #fff;
$disable-button: #5a5858;
$row-background: rgba(218, 227, 239, 0.3);
$input-border: #c4c4c4;
$input-background: #f6f7f9;
$error: #f4530e;
$warning: #ff9800;

$section-background: rgba($primary-accent, 0.3);

$section-title-color: #fff;
$input-background: #fff;
$loading-background: #fff;
$button-color: #fff;
$flag-border: #fff;
$breadcrumbs-text: #fff;

%section-title {
  padding: 0.5rem 1rem;
  background-color: $primary;
  color: $section-title-color;
  font-weight: 500;
}
