.search-bar {
  display: flex;
  height: 3rem;
  padding: 0 1rem;
  align-items: center;
  background-color: $primary-accent;
  gap: 2rem;

  > .MuiSvgIcon-root {
    color: $primary;
  }

  .filters {
    display: flex;
    flex: 1;
    gap: 2rem;

    .filter {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      .label {
        color: $primary;
        font-weight: 500;
      }

      .MuiInputBase-root {
        font-size: 0.875rem;
      }
    }
  }

  .actions {
    display: flex;
    gap: 0.5rem;
  }
}

.menu-item.MuiMenuItem-root {
  font-size: 0.875rem;
}
