footer {
  display: grid;
  align-items: center;
  padding: 0.75rem;
  border-top: 1px solid $primary;
  grid-template-columns: 1fr max-content 1fr;

  span.copyright {
    color: $primary;
    grid-column: 2;
  }

  a {
    display: flex;
    justify-self: flex-end;

    img.qulto-logo {
      height: 1.5rem;
      grid-column: 3;
    }
  }
}
