.MuiOutlinedInput-root {
  background-color: $input-background;
  border-radius: 3px;
}

.small-input input.MuiOutlinedInput-input {
  padding: 0.25rem 0.75rem;
}

.small-input .MuiSelect-select {
  padding: 0.25rem 0.5rem;
}

.form {
  .MuiOutlinedInput-root {
    background-color: $input-background;

    input {
      padding: 0.375rem 0.5rem;
    }
  }
}
