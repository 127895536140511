.flag-border {
  display: inline-flex;
  border: 2px solid $primary;
  border-radius: 50%;

  .flag-sizer {
    overflow: hidden;
    width: 1.25rem;
    height: 1.25rem;
    border: 1px solid $flag-border;
    border-radius: 50%;
    line-height: 1;

    .flag {
      display: flex;
      top: -1px;
      left: -4.335px;
      width: max-content;
      height: 1.25rem;
    }
  }
}
