.loading {
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 1.5rem;
  gap: 2rem;

  .MuiCircularProgress-svg {
    color: $primary;
  }
}
