.import-list-container {
  width: 70%;
  margin: 0 auto;
  .import-list-title {
    @extend %section-title;
    margin-top: 2rem;
  }

  .table .table-body .table-heads .actions-column-placeholder {
    display: grid;
  }

  .import-list {
    display: grid;
    grid-template-columns: repeat(4, 3fr) 1fr;
    grid-row-gap: 0.25rem;
  }

  .import-list-message {
    margin: 1rem 0;
    text-align: center;
  }

  .loading {
    width: auto;
    height: auto;
    background-color: $loading-background;
    margin: 2rem;
  }

  .open-import-loading-container .loading {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.import-result-modal {
  .MuiDialog-paper {
    max-width: 90rem;
    width: 90rem;
  }

  .import-result-list {
    display: grid;
    grid-template-columns: 1fr 2fr 2fr 1fr 1fr 1fr 5fr;
    grid-row-gap: 0.25rem;

    .import-result-item {
      .field-data {
        max-width: 25rem;

        .import-status-success,
        .import-status-failed {
          > div {
            display: flex;
            align-items: center;
            gap: 0.25rem;
          }

          svg {
            width: 1.25rem;
            height: 1.25rem;
          }
        }

        .import-status-success {
          svg {
            color: $secondary;
          }

          .status-reason {
            font-size: 0.75rem;
            color: $warning;
          }
        }

        .import-status-failed {
          svg {
            color: $error;
          }

          .status-reason {
            font-size: 0.75rem;
            color: $error;
          }
        }
      }
    }
  }
}
