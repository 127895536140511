.system-list-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.system-list {
  display: grid;
  grid-template-columns: max-content max-content 1fr 1fr max-content max-content max-content;
  grid-row-gap: 0.25rem;

  .heads {
    display: contents;

    .head {
      width: fit-content;
      display: flex;
      align-items: center;
      padding: 0.25rem 1rem;
      font-weight: 500;
      text-transform: uppercase;
      cursor: pointer;

      .MuiSvgIcon-root {
        margin: 0.25rem;
        color: $primary;
        font-size: 1.25rem;
      }

      .sort-placeholder {
        width: 1.25rem;
        height: 1.25rem;
        margin: 0.25rem;
      }
    }
  }

  .system-list-item {
    .expand-button {
      padding: 0 0.5rem;
      border-radius: 0;
      font-size: 1.25rem;
    }

    .copy-button {
      padding: 0;
      color: $primary;

      .MuiSvgIcon-root {
        margin-right: 0.25rem;
        font-size: 1.25rem;
      }
    }
  }

  .system-details {
    display: flex;
    padding: 0.5rem 2rem;
    align-items: center;
    justify-content: center;
    grid-column: span 6;
    gap: 2rem;

    .invoice-field {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;

      .label {
        font-weight: 500;
        text-transform: uppercase;
      }

      .value {
        min-width: 5rem;
      }
    }
  }
}

h1 {
  text-align: center;
  justify-content: center;
  margin-left: 2rem;
  color: $dialog-background;
  font-size: 1.5rem;
  font-weight: 500;
  text-transform: uppercase;
}

.systemlog-columns {
  height: 2.25rem;
  margin: 0.25rem 0;
  &.titles {
    padding-left: 2.5rem;
    color: $primary;
    font-size: 1rem;
    font-weight: 500;
    text-transform: uppercase;
  }
}

.confirmation-modal-container {
  align-items: center;
  justify-content: center;
}

.MuiFormControl-root {
  margin: 1rem;
}

.search-types {
  margin-right: 2rem;
  width: 10rem;
}

.MuiGrid-root .input-container {
  margin-bottom: 2rem;
}

.MuiInputBase-formControl {
  margin-bottom: 0;
}

.search-type {
  margin-right: 2rem;
}

.progress-container {
  height: 100%;
  position: absolute;
  left: 0;
  width: 100%;
  overflow: hidden;
  align-content: center;
  justify-content: center;
}

.system-list-filter-type .MuiSelect-select {
  min-width: 7rem;
}

.system-list-filter-text .MuiOutlinedInput-input {
  min-width: 15rem;
}
