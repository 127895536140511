.file-upload-dropzone {
  width: 70%;
  margin: 0 auto;
  padding: 2rem;
  border: 2px dotted $input-border;
  text-align: center;
  cursor: pointer;

  .file-upload-icon {
    width: 4rem;
    height: 4rem;
    color: $input-border;
  }

  .file-upload-loading {
    height: 0.25rem;
    .MuiLinearProgress-bar {
      background-color: $primary;
    }
  }
}
