.paginator {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;

  .first,
  .prev,
  .next,
  .last {
    width: 2rem;
    height: 2rem;
    line-height: normal;

    .MuiSvgIcon-root {
      font-size: 1.25rem;
    }
  }

  .page-option {
    min-width: auto;
    width: 2rem;
    height: 2rem;
    padding: 0;
    border-radius: 50%;
    line-height: normal;
    font-weight: 500;
    color: $primary;

    &.current-page {
      background-color: $primary;
      color: $button-color;
      font-weight: 700;
    }
  }
}
